export enum Features {
  settingsPageAccess = 'settingsPageAccess',
  accountSettingsPage = 'accountSettingsPage',
  integrationsSettingsPage = 'integrationsSettingsPage',
  driverSignUp = 'driverSignUp',
  chargbeeTrialBanner = 'chargbeeTrialBanner',
  showWelcome = 'showWelcome',
  showInvoices = 'showInvoices',
  truckbaseInvoicing = 'truckbaseInvoicing',
  dynamicWebTwain = 'dynamicWebTwain',
  showInbox = 'showInbox',
  showCalculateEffectiveRate = 'showCalculateEffectiveRateButton',
  createLoadFromPdf = 'createLoadFromPdf',
  showDriverGrossPayEstimate = 'showDriverGrossPayEstimate',
  teamDrivers = 'teamDrivers',
  splitLoads = 'splitLoads',
  createInvoiceOnMobile = 'createInvoiceOnMobile',
  driverSettlements = 'driverSettlements',
  ediImports = 'ediImports',
  emailImports = 'emailImports',
  customerPortal = 'customerPortal',
  liveTracking = 'liveTracking',
  routeInfoV2 = 'routecomponentV2',
  rateConBanner = 'rateConBanner',
  fuelTransactions = 'fuelTransactions',
  metabase = 'metabase',
  addressLocationLink = 'addressLocationLink',
  showOrganizations = 'showOrganizations',
  stopSignature = 'stopSignature',
  qboSetCustomLoadField = 'qboSetCustomLoadField',
  maintenanceLog = 'maintenanceLog',
  automaticallyFilterDispatchUserLoads = 'automaticallyFilterDispatchUserLoads',
  otherExpenses = 'otherExpenses',
  sendQboInvoiceInternally = 'sendQboInvoiceInternally',
  flexibleTagging = 'flexibleTagging',
  downloadAppBanner = 'downloadAppBanner',
  zipAttachments = 'zipAttachments',
  calendarV2 = 'calendarV2',
  otherPayInDriverPayModal = 'otherPayInDriverPayModal',
  butlerRateConModelDropdown = 'butlerRateConModelDropdown',
  dummyDeleteAccountButton = 'dummyDeleteAccountButton',
  bulkLoadImport = 'bulkLoadImport',
  enableMarkAsDelivered = 'enableMarkAsDelivered',
  systemCameraForDocumentUpload = 'systemCameraForDocumentUpload',
  showDriverProfile = 'showDriverProfile',
  factoring = 'factoring',
  intercomWidget = 'intercomWidget',
  reportsUnderConstruction = 'reportsUnderConstruction',
  mobileLoadInbox = 'mobileLoadInbox',
  equipmentFile = 'equipmentFile',
  notificationBanner = 'notificationBanner',
  downForMaintenance = 'downForMaintenance',
  androidCreateGeoLink = 'androidCreateGeoLink'
}
